<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <div v-show="showMap" id="map" class="elevation-x container-map b-rad-small" />
    <v-tooltip v-if="$vuetify.breakpoint.lgAndUp" top>
      <template v-slot:activator="{ on }">
        <v-btn class="mx-2 resize-map p-abs" fab dark small color="primary" v-on="on"
               @click="toggleClass()"
        >
          <font-awesome-icon size="lg" :icon="['fal', 'expand-wide']" />
        </v-btn>
      </template>
      <span>{{ MapActive ? 'Réduire la carte' : 'Agrandir la carte' }}</span>
    </v-tooltip>

    <v-dialog :retain-focus="false" scrollable eager persistent :value="infoMarker" max-width="600">
      <v-card class="dialog-card">
        <v-card-text class="px-0">
          <v-list two-line class="results-list pb-0">
            <v-list-item v-for="obj in professional" :key="obj.uid" @click.stop="goToProfile(obj)">
              <v-list-item-avatar width="60px" height="60px">
                <user-avatar-img v-if="obj" :user="obj" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-alt text-h6 primary--text">{{ obj.fullName }}</v-list-item-title>
                <v-list-item-subtitle>
                  <address-displayer :address="obj.main_address" />
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="green lighten-1" @click.stop="action(obj)">
                    {{ displayText(obj.type) }}
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-end pt-0">
          <v-btn x-small color="primary" @click="close">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import AddressDisplayer from '@/modules/core/components/AddressDisplayer'
  import UserAvatarImg from '@/modules/core/layout/UserAvatarImg'

  export default {
    name: 'GoogleMap',
    components: {UserAvatarImg, AddressDisplayer},
    props: {
      showMap: {
        type: Boolean
      }
    },
    data() {
      return {
        MapActive: false,
        markers: [],
        otherMarkers: [],
        locationMarkers: {},
        map: null,
        infoMarker: false,
        name: '',
        uid: '',
        address: '',
        professional: null,
        type: 'professionals',
        textAction: 'Ajouter',
        callback: null,
        color: {
          professionals: '#34afe4',
          patients: '#a9a858',
          groups: '#d66a98'
        }
      }
    },
    methods: {
      toggleClass: function () {
        this.MapActive = !this.MapActive
        this.$emit('MapActive', this.MapActive)
      },
      displayText(type) {
        return type === 'professionals' ? 'mdi-account-plus-outline' : 'mdi-magnify'
      },
      goToProfile(obj) {
        if (obj.type === 'professionals') {
          this.$router.push({name: 'professional-profile', params: {uid: obj.uid}})
        } else if (obj.type === 'patients') {
          this.$router.push('/patient/' + obj.uid)
        } else {
          this.$router.push('/patient-file/' + obj.uid)
        }
      },
      initMap(address) {
        this.map = new this.$google.maps.Map(document.getElementById('map'), {
          zoom: (!address || address === '' ? 6 : 12),
          mapTypeControl: false,
          navigationControl: false,
          mapTypeId: this.$google.maps.MapTypeId.ROADMAP,
          streetViewControl: false,
          fullscreenControl: false
        })
      },
      setZoom(address) {
        let zoom = (!address || address === '' ? 6 : 12)
        this.map.setZoom(zoom)
      },
      action(obj) {
        this.type = obj.type
        this.clickBtn(obj)
      },
      async clickBtn(obj) {
        switch (this.type) {
          case 'professionals':
            if (await this.$confirm('Envoyer une demande de contact à ' + obj.fullName + ' ?')) {
              let [err] = await this.$store.dispatch('contacts/sendRequest', obj)

              this.$root.$emit('snackbar:open', {
                snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
                type: 'success',
                title: 'Invitation envoyée',
                subtitle: 'Invitation envoyée avec succès.'
              })
              if (err) {
                this.$root.$emit('snackbar:open', {
                  snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
                  type: 'info',
                  title: 'Invitation déjà envoyée',
                  subtitle: 'Invitation déja envoyée à cet utilisateur.'
                })
                return
              }
            }
            break
          case 'patients':
            this.$router.push({name: 'patient-profile', params: {uid: obj.uid}})
            break
          case 'groups':
            this.$router.push({name: 'team-profile', params: {uid: obj.uid}})
            break
          default:
            break
        }
      },
      setMarkersLocation() {
        this.locationMarkers = {}
      },
      setLatLng(data) {
        let professional = data
        let color = (professional.type === 'professionals' ? this.color.professionals : (professional.type === 'patients' ? this.color.patients : this.color.groups))
        let marker = null
        if (data.main_address.location) {
          if (this.locationMarkers [data.main_address.location] === undefined) {
            this.locationMarkers [data.main_address.location] = []
          }
          this.locationMarkers [data.main_address.location].push(data)
          let location = data.main_address.location.split(',')
          if (this.markers.length === 0) {
            this.map.setCenter({lat: parseFloat(location[0]), lng: parseFloat(location[1])})
          }
          marker = new this.$google.maps.Marker({
            icon: this.pinSymbol(color),
            position: {lat: parseFloat(location[0]), lng: parseFloat(location[1])},
            map: this.map
          })
        } else if (data.main_address.latitude && data.main_address.longitude) {
          if (this.locationMarkers [data.main_address.latitude + ',' + data.main_address.longitude] === undefined) {
            this.locationMarkers [data.main_address.latitude + ',' + data.main_address.longitude] = []
          }
          this.locationMarkers [data.main_address.latitude + ',' + data.main_address.longitude].push(data)
          if (this.markers.length === 0) {
            this.map.setCenter({lat: data.main_address.latitude, lng: data.main_address.longitude})
          }
          marker = new this.$google.maps.Marker({
            icon: this.pinSymbol(color),
            position: {
              lat: data.main_address.latitude,
              lng: data.main_address.longitude
            }, map: this.map
          })
        } else {
          this.geocode(data, color)
        }
        if (marker) {
          this.markers.push(marker)
          marker.addListener('click', (e) => {
            this.professional = this.locationMarkers [e.latLng.lat() + ',' + e.latLng.lng()]
            this.infoMarker = true
          })
        }
      },
      geocode(data, color) {
        let geocoder = new this.$google.maps.Geocoder()
        let address = data.main_address
        let addressGeocode = ''
        if (address) {
          if (address.zip_code && address.city) {
            let zipcode = typeof address.zip_code === 'object' ? address.zip_code.name : address.zip_code
            let city = typeof address.city === 'object' ? address.city.name : address.city
            if (city === undefined || zipcode === undefined || !address.address1) {
              return
            }
            addressGeocode = address.address1 + ' ' + zipcode + ' ' + city
          }
          geocoder.geocode({'address': addressGeocode}, (results, status) => {
            if (status === this.$google.maps.GeocoderStatus.OK) {
              if (status !== this.$google.maps.GeocoderStatus.ZERO_RESULTS) {
                if (this.locationMarkers [results[0].geometry.location] === undefined) {
                  this.locationMarkers [results[0].geometry.location] = []
                }
                this.locationMarkers [results[0].geometry.location].push(data)
                if (this.markers.length === 0) {
                  this.map.setCenter(results[0].geometry.location)
                }
                let marker = new this.$google.maps.Marker({
                  icon: this.pinSymbol(color),
                  position: results[0].geometry.location,
                  map: this.map,
                  title: addressGeocode
                })
                marker.addListener('click', () => {
                  this.professional = this.locationMarkers [results[0].geometry.location]
                  this.infoMarker = true
                })
                this.markers.push(marker)
              }
            }
          })
        }
      },
      setMapOnAll() {
        for (let i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(null)
        }
        this.markers = []
        this.locationMarkers = {}
      },
      pinSymbol(color) {
        return {
          path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
          fillColor: color,
          fillOpacity: 1,
          strokeColor: '#000',
          strokeWeight: 2,
          scale: 1
        }
      },
      close() {
        this.infoMarker = false
      }
    }
  }
</script>

<style lang="scss">
  #map {
    height: calc(100vh - 120px);
  }
  .dialog-card {
    border-top: 3px solid #99d2d8;
    .results-list {
      .v-list-item:not(:last-of-type) {
        border-bottom: 1px solid #f1f1f1;
      }
    }
  }
  .resize-map {
    top: 23px;
    right: 16px;
  }
</style>
