<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list-item class="pb-0 pb-md-8">
    <v-list-item-content>
      <h4 class="text-md-h4 text-subtitle-1 font-alt">Résultats pour votre recherche</h4>
      <v-chip-group column>
        <template v-if="filters.terms">
          <v-chip small>
            <v-avatar left class="mr-0">
              <font-awesome-icon :icon="['fal', 'pencil']" size="sm" class="primary--text" />
            </v-avatar>
            {{ filters.terms }}
          </v-chip>
        </template>
        <template v-if="filters.address">
          <v-chip small>
            <v-avatar left class="mr-0">
              <font-awesome-icon :icon="['fal', 'map-marker-alt']" size="sm" class="primary--text" />
            </v-avatar>
            {{ filters.address }}
          </v-chip>
        </template>
        <template v-if="filters.jobs">
          <v-chip v-for="job in filters.jobs" :key="job.uid" small>
            <v-avatar left class="mr-0">
              <font-awesome-icon :icon="['fal', 'stethoscope']" size="sm" class="primary--text" />
            </v-avatar>
            {{ job.name }}
          </v-chip>
        </template>
        <template v-if="filters.specialties">
          <v-chip v-for="specialty in filters.specialties" :key="specialty.uid" small>
            <v-avatar left class="mr-0">
              <font-awesome-icon :icon="['fal', 'books-medical']" size="sm" class="primary--text" />
            </v-avatar>
            {{ specialty.name }}
          </v-chip>
        </template>
      </v-chip-group>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn :fab="$vuetify.breakpoint.mdAndDown" :x-small="$vuetify.breakpoint.mdAndDown" :small="$vuetify.breakpoint.lgAndUp" color="primary white--text" @click="backToSearch">
        <span v-if="$vuetify.breakpoint.lgAndUp">Modifier ma recherche</span>
        <font-awesome-icon v-else :icon="['fad', 'pencil']" size="lg" class="white--text" />
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'SearchResultsSummary',
    computed: {
      ...mapState('advancedSearch', ['filters'])
    },
    methods: {
      backToSearch() {
        this.$store.commit('advancedSearch/setSearchVisible')
      }
    }
  }
</script>
