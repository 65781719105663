<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div v-scroll="next">
    <v-list class="elevation-x pt-0">
      <v-list-item-group>
        <search-results-summary />
        <v-divider />
        <template v-for="(result, index) in results">
          <search-result-item :key="result.uid" :result="result" />
          <v-divider v-if="index + 1 < results.length" :key="'divider-'+index" class="my-0" />
        </template>
      </v-list-item-group>
      <empty-list v-if="results.length === 0" title="Aucun résultat"
                  subtitle="Modifier votre recherche pour plus de résultats"
      />
      <div v-if="searching">
        <v-progress-linear color="primary" indeterminate height="4" />
      </div>
    </v-list>
  </div>
</template>

<script>
  import EmptyList from '@/modules/core/layout/EmptyList'
  import SearchResultsSummary from './SearchResultsSummary'
  import SearchResultItem from './SearchResultItem'
  import {mapState} from 'vuex'
  export default {
    name: 'SearchResultsContainer',
    components: {SearchResultItem, SearchResultsSummary, EmptyList},
    props: {
      moreResults: Boolean
    },
    computed: {
      ...mapState('advancedSearch', ['searching', 'results'])
    },
    methods: {
      getScrollTop() {
        return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
      },
      getDocumentHeight() {
        let body = document.body
        let html = document.documentElement
        return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
      },
      async next() {
        if(!this.moreResults || this.searching) {
          return false
        }
        if (this.getScrollTop() < (this.getDocumentHeight() - window.innerHeight - 50)) {
          return false
        } else {
          this.$emit('next')
        }
        return false
      }
    }
  }
</script>
