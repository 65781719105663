<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div class="w-search">
    <v-tabs v-if="$vuetify.breakpoint.mdAndDown" v-model="tabMobile" centered grow class="mb-1 elevation-x">
      <v-tab key="listing" class="text-caption" :class="$vuetify.theme.dark ? 'white--text' :'primary--text'"
             @click="wideMap = false"
      >
        <font-awesome-icon :icon="['fad', 'stream']" size="lg" class="mr-2"
                           :class="$vuetify.theme.dark ? 'white--text' :'primary--text'"
        />
        Liste
      </v-tab>
      <v-tab key="map" class="text-caption" :class="$vuetify.theme.dark ? 'white--text' :'primary--text'"
             @click="wideMap = true"
      >
        <font-awesome-icon :icon="['fad', 'map-marker-alt']" size="lg" class="mr-2"
                           :class="$vuetify.theme.dark ? 'white--text' :'primary--text'"
        />
        Carte
      </v-tab>
      <v-tabs-slider :class="$vuetify.theme.dark ? 'white--text' :'primary--text'"/>
    </v-tabs>

    <v-row v-if="$vuetify.breakpoint.lgAndUp" class="w-search-results p-rel" :class="{ Mapactive: wideMap }">
      <v-col cols="12" md="7" class="w-search-results-container">
        <search-results-container :more-results="moreResults"
                                  :results="results" @next="next"
        />
      </v-col>
      <v-col cols="12" md="5" class="map-container">
        <google-map v-show="results.length > 0" ref="googleMap" :show-map="showMap" class="w-search-map p-rel"
                    @MapActive="toggleMapDisplay"
        />
      </v-col>
    </v-row>

    <v-row v-else class="w-search-results p-rel" :class="{ Mapactive: wideMap }">
      <v-col v-if="!wideMap" cols="12" md="7" class="w-search-results-container">
        <search-results-container :more-results="moreResults"
                                  :results="results" @next="next"
        />
      </v-col>
      <v-col v-show="wideMap" cols="12" md="5" class="map-container">
        <google-map v-show="results.length > 0" ref="googleMap" :show-map="showMap" class="w-search-map p-rel"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import GoogleMap from './GoogleMap'
  import SearchResultsContainer from './SearchResultsContainer'
  import {mapState} from 'vuex'

  export default {
    name: 'SearchResults',
    components: {
      SearchResultsContainer,
      GoogleMap
    },
    props: {
      showMap: {
        type: Boolean
      },
      disabledTabMap: {
        type: Boolean
      },
      moreResults: {
        type: Boolean
      }
    },
    data() {
      return {
        tab: 'results',
        wideMap: false,
        tabMobile: null
      }
    },
    computed: {
      ...mapState('advancedSearch', ['results'])
    },
    mounted() {
      this.tabMobile = 'listing'
    },
    methods: {
      next() {
        this.$emit('next')
      },
      toggleMapDisplay() {
        this.wideMap = !this.wideMap
      }
    }
  }
</script>

<style lang="scss">
.whap-choice {
  .v-tabs-bar {
    height: 35px !important;

    .v-tab {
      text-transform: inherit;
    }
  }
}


.w-search {
  .whap-choice {
    display: none;
  }

  .w-search-map {
    position: sticky !important;
    top: 60px;

    .container-map {
      height: calc(100vh - 60px) !important;
    }
  }

  .Mapactive {
    flex-direction: column;

    .map-container {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .w-search-results-container {
      max-width: 100% !important;
      order: 2;
      padding-top: 0 !important;
    }

    .w-search-map {
      .container-map {
        height: 70vh !important;
      }
    }
  }
}

</style>
