<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-dialog fullscreen :value="!displayResults" transition="dialog-top-transition" hide-overlay>
    <v-card color="primary" class="d-flex align-center">
      <v-toolbar color="primary" absolute flat dense width="100%">
        <v-img max-width="90px" src="@/assets/img/applogo/mspace-logo-light.png" />
        <v-spacer />
        <v-toolbar-items>
          <v-btn dark icon @click="$router.go(-1)">
            <v-icon large>$close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-col cols="12" lg="9" xl="6" class="mx-auto" style="z-index: 222">
        <div class="text-md-h3 text-h4 white--text font-alt font-weight-light mb-8">Recherche avancée</div>
        <v-form id="c-search-view" class="w-100">
          <div ref="form" class="filter-search">
            <div class="content-form">
              <v-text-field v-model="formData.terms" class="mb-0 font-alt" label="Nom, prénom" placeholder="Nom, prénom"
                            solo @keyup.enter="submit"
              />

              <!--              <vue-google-autocomplete id="autocomplete-location"-->
              <!--                                       v-model="formData.address"-->
              <!--                                       class="mb-7 location-input col-md-12 elevation-x b-rad-small font-alt"-->
              <!--                                       :class="$vuetify.theme.dark ? 'darkmode' : 'white'"-->
              <!--                                       :country="['fr','be']"-->
              <!--                                       dense-->
              <!--                                       outlined-->
              <!--                                       placeholder="Ville, code postal"-->
              <!--                                       label="Ville, code postal"-->
              <!--                                       types="(regions)"-->
              <!--                                       @keyup.enter="submit"-->
              <!--                                       @inputChange="changeAddress"-->
              <!--                                       @placechanged="getAddressData"-->
              <!--              />-->

<!--              <v-select v-model="formData.types" class="font-alt" :items="itemsTypes" :class="displayMD" multiple-->
<!--                        placeholder="Type de recherche"-->
<!--                        label="Type de recherche" solo clearable-->
<!--              />-->

              <v-select v-model="formData.types" class="font-alt" :items="itemsTypes" multiple
                        placeholder="Type de recherche"
                        label="Type de recherche" solo clearable
              />

              <!--              <v-select v-model="formData.jobs" class="font-alt" :disabled="loading.jobs" :items="itemsJob" :loading="loading.jobs" chips-->
              <!--                        :class="displayMD" clearable-->
              <!--                        deletable-chips-->
              <!--                        item-text="name"-->
              <!--                        item-value="uid" multiple placeholder="Métier" label="Métier" return-object small-chips solo-->
              <!--                        @change="changeJobs"-->
              <!--              />-->

              <!--              <v-autocomplete v-model="formData.specialties" :disabled="loading.specialties || disabled.specialties"-->
              <!--                              :items="itemsSpecialties"-->
              <!--                              :loading="loading.specialties"-->
              <!--                              chips clearable deletable-chips-->
              <!--                              item-text="name" item-value="uid" label="Spécialités" placeholder="Spécialités" multiple-->
              <!--                              return-object small-chips solo-->
              <!--                              class="font-alt"-->
              <!--              />-->
            </div>

            <div class="submit-search text-center mt-9 mt-md-5">
              <v-btn v-if="!searching" class="mr-1" color="primary" @click="reset">
                Réinitialiser
              </v-btn>
              <v-btn :disabled="searching" :loading="searching" dark color="secondary" @click="submit">
                Rechercher
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  // import VueGoogleAutocomplete from 'vue-google-autocomplete'
  // import {isEnabled} from 'vue-feature-flipping'

  export default {
    name: 'SearchForm',
    components: {
      // VueGoogleAutocomplete
    },
    data() {
      return {
        // itemsJob: [],
        // itemsSpecialties: [],
        itemsTypes: [
          // {value: 'groups', text: 'Groupe'},
          {value: 'patient', text: 'Patient'},
          {value: 'professional', text: 'Professionnel'}
        ],
        formData: {
          terms: null,
          // address: null,
          types: ['professional']
          // jobs: [],
          // specialties: []
        }
        // loading: {
        //   specialties: false,
        //   jobs: true
        // },
        // disabled: {
        //   specialties: false
        // }
      }
    },
    computed: {
      ...mapState('advancedSearch', ['filters', 'savedData', 'searched', 'searching', 'displayResults'])
      // ...mapState('jobs', ['jobs', 'specialties']),
      // displayMD() {
      //   return isEnabled('MEDILIEN-MD-SEARCH') ? 'hidden' : ''
      // }
    },
    // created() {
    // this.getJobs().then(() => {
    //   this.itemsJob = this.jobs
    //   this.loading.jobs = false
    // if (isEnabled('MEDILIEN-MD-SEARCH')) {
    //   this.restrictJobs()
    // }
    // })
    // },
    methods: {
      // ...mapActions('jobs', ['storeJobs', 'storeSpecialties', 'getSpecialties', 'getJobs']),
      ...mapActions('advancedSearch', ['submitSearch']),

      // Load specialties from a specific job Uid
      // getSpecialtiesFromJob(jobUid) {
      //   this.getSpecialties(jobUid).then((data) => {
      //     this.itemsSpecialties = this.sortSpecialtiesResults(data)
      //   }).finally(() => {
      //     this.loading.specialties = false
      //     this.disabled.specialties = false
      //   })
      // },

      // Medilien specific operation to restrict jobs to MD only
      // restrictJobs() {
      //   for (let job of this.jobs) {
      //     if (job.name === 'Médecin') {
      //       this.formData.jobs.push(job)
      //       this.$store.commit('advancedSearch/setJob', [job])
      //       this.changeJobs()
      //     }
      //   }
      // },

      // Callback for job select change
      // changeJobs() {
      //   if (this.formData.jobs.length !== 1) {
      //     // We are disabling specialties if more than a job or no job are selected
      //     this.disabled.specialties = true
      //     this.formData.specialties = []
      //   } else {
      //     // Else retrieve the specialties from the selected job
      //     this.getSpecialtiesFromJob(this.formData.jobs[0].uid)
      //   }
      // },

      submit() {
        // if (document.getElementById('autocomplete-location').value === '') {
        //   this.formData.address = null
        // }
        if (this.formData.types.length === 0) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Type de recherche requis',
            subtitle: 'Veuillez renseigner un type de recherche.'
          })
          return
        }
        if (!this.formData.terms) {
          // if (!this.formData.terms && !this.formData.address && (!this.formData.jobs || this.formData.jobs.length === 0) &&
          //   !this.formData.specialties) {
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Nom ou prénom requis',
            // title: 'Un champ minimum requis',
            subtitle: 'Une erreur est survenue.'
          })
          return
        }
        this.submitSearch({
          filters: this.formData,
          page: 1,
          resultsSearch: [],
          moreResults: true
        })
        this.$emit('onSubmit')
      },
      // Reset the form inputs
      reset() {
        this.formData.terms = null
        // this.formData.address = null
        // document.getElementById('autocomplete-location').value = ''
        // this.formData.specialties = []
        // this.formData.jobs = []
      }

      // Google autocomplete specific block
      // changeAddress(val) {
      //   this.formData.address = val.newVal
      // },

      // Google autocomplete specific block
      // getAddressData: function (addressData) {
      //   this.formData.address = addressData.locality
      // },

      // Apply filtering or sorting to a set of specialties
      // sortSpecialtiesResults(specialties) {
      //   let outputArray = []
      //   for (let specialty of specialties) {
      //
      //     // Medilien specific filtering
      //     if (specialty.name === 'Chirgurgie urologique' || specialty.name === 'Acupuncture') {
      //       continue
      //     }
      //     // End of Medilien specific filtering
      //
      //     outputArray.push(specialty)
      //   }
      //   return outputArray.sort((a, b) => (a.name > b.name) ? 1 : -1)
      // }
    }
  }
</script>
<style lang="scss" scoped>
.location-input {
  font-size: 0.83rem !important;
  height: 48px;
  border-radius: 5px;

  &.darkmode {
    background: #262931;
  }
}
</style>
