<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-container fluid>
    <search-form v-show="!displayResults" @onSubmit="submit" />
    <div v-show="displayResults">
      <search-results
        ref="searchresults" :disabled-tab-map="disableTabMap"
        :more-results="moreResults"
        :show-map="showMap"
        @back-to-search="showFormSearch"
        @next="next"
      />
    </div>
  </v-container>
</template>

<script>
  import SearchResults from '../components/SearchResults'
  import SearchForm from '../components/SearchForm'
  import {mapActions, mapState} from 'vuex'
  import {search} from '@/modules/user/api'

  const RESULTS_PER_PAGE = 15
  // const RESULTS_PER_PAGE_GEOCODED = 50

  export default {
    name: 'Search',
    components: {
      SearchForm,
      SearchResults
    },
    data: () => ({
      showBack: true,
      showBackSearch: false,
      loadingSearch: false,
      moreResults: true,
      showMap: false,
      haveNextPage: true,
      haveMarkers: false,
      disableTabMap: false,
      itemsBreadcrumbs: [],
      resultSize: RESULTS_PER_PAGE
    }),
    computed: {
      ...mapState('advancedSearch', ['displayResults', 'searching', 'filters', 'page', 'results', 'searched']),
      ...mapState('layout', ['isMobile'])
    },
    created() {
      if (this.searched) {
        this.$store.commit('advancedSearch/setResultsVisible')
        this.showMap = true
        this.showBack = false
        this.showBackSearch = true
        setTimeout(() => {
          this.initGoogleMap(this.filters.address)
        }, 10)
      }
    },

    mounted() {
      this.itemsBreadcrumbs.push({text: 'Recherche', disabled: true, to: '/'})
    },
    methods: {
      ...mapActions('advancedSearch', ['setSearching', 'incrementPage', 'appendResults']),

      showFormSearch() {
        this.$store.commit('advancedSearch/setSearchVisible')
      },

      async next() {
        if (this.displayResults && this.haveNextPage) {
          this.incrementPage()
          this.setSearching(true)
          await this.searchProfessionals()
        }
      },

      async searchProfessionals() {
        this.setSearching(true)
        this.resultSize = RESULTS_PER_PAGE
        // this.resultSize = this.filters.address ? RESULTS_PER_PAGE_GEOCODED : RESULTS_PER_PAGE
        if (!this.searching) {
          this.showMap = false
        }
        this.haveNextPage = false
        this.disableTabMap = false

        try {
          let result = (await search(this.page, this.resultSize, {
            search_term: this.filters.terms,
            type: this.filters.types.length === 1 ? this.filters.types[0] : 'user'
          }, null, ['patient_address', 'professional_address', 'professional_job', 'professional_skills', 'professional_specialties'])).data.list

          this.showBack = false
          this.showBackSearch = true
          this.haveNextPage = true
          if (this.isMobile) {
            this.$refs.searchresults.$refs.tabs.callSlider()
          }

          if (result.length < this.resultSize) {
            this.moreResults = false
          }

          if (this.page === 1 && result.length === 0) {
            this.$store.commit('advancedSearch/clearResults')
          } else {
            this.showMap = true
            this.appendResults(result)
          }
          this.$store.commit('advancedSearch/setPage', this.page)
          this.initGoogleMap(this.filters.address)
          this.$store.commit('advancedSearch/setResultsVisible')
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Une erreur est survenue pendant votre recherche. Veuillez reessayer.'
          })
          throw error
        } finally {
          this.setSearching(false)
        }
      },

      submit() {
        this.searchProfessionals()
        this.$refs.searchresults.$refs.googleMap.setMapOnAll()
      },

      initGoogleMap(address) {
        if (!this.$refs.searchresults.$refs.googleMap.map) {
          this.$refs.searchresults.$refs.googleMap.initMap(address)
        } else {
          this.$refs.searchresults.$refs.googleMap.setZoom(address)
        }
        this.$refs.searchresults.$refs.googleMap.setMarkersLocation()
        for (let result of this.results) {
          if (result.main_address) {
            this.$refs.searchresults.$refs.googleMap.setLatLng(result)
          }
        }
        if (this.$refs.searchresults.$refs.googleMap.markers.length === 0) {
          this.disableTabMap = true
        }
      }
    }
  }
</script>
