<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list-item two-line :to="
                 (result.type === 'user:professional' ? {name: 'professional-profile', params: {uid: result.uid}} :
                   (result.type === 'user:patient' ? {name: 'patient-profile', params: {uid: result.uid}} :
                     '#'))"
               transition="slide-y-transition" class="align-baseline"
  >
<!--  <v-list-item two-line :to="-->
<!--                 (result.type === 'professional' ? {name: 'professional-profile', params: {uid: result.uid}} :-->
<!--                   (result.type === 'patient' ? {name: 'patient-profile', params: {uid: result.uid}} :-->
<!--                     {name: 'team-profile', params: {uid: result.uid}}))"-->
<!--               transition="slide-y-transition" class="align-baseline"-->
<!--  >-->
    <v-list-item-avatar v-if="result && result.type === 'user:professionals' || result.type === 'user:patients'"
                        class="mr-3"
    >
      <user-avatar-img :user="result" />
    </v-list-item-avatar>
<!--    <v-list-item-avatar v-if="result.type === 'groups'" class="mr-3">-->
<!--      <v-img :src="require('@/assets/img/usergroup.png')" />-->
<!--    </v-list-item-avatar>-->

    <v-list-item-content>
      <v-list-item-title v-if="result.type === 'user:professional' || result.type === 'user:patient'" class="weight-600">
        {{ result.full_name }}
<!--        <v-chip v-if="result.sort && returnDistance(result.sort)" class="ml-1" x-small v-text="returnDistance(result.sort)" />-->
      </v-list-item-title>
      <v-list-item-title v-else class="weight-600">
        {{ result.name }}
      </v-list-item-title>

      <v-list-item-subtitle />
      <v-list-item-subtitle v-if="result.type === 'user:professional'">
        <div class="d-inline-block primary--text">{{ result.job ? result.job.name : 'Métier non défini' }}</div>
        <div v-if="result.specialties" class="d-flex flex-wrap">
          <template v-for="(specialty, index) in result.specialties">
            <span v-if="!result.job || result.job.name !== specialty.name" :key="index" class="pr-2">{{ specialty.name }}</span>
          </template>
        </div>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="result.type === 'user:patient'" class="primary--text">
        {{ $dayjs().diff(result.birth_date, 'years') }} ans
      </v-list-item-subtitle>
<!--      <v-list-item-subtitle v-else-if="result.type === 'groups'" class="primary&#45;&#45;text"-->
<!--                            v-text="result.members + ' membres'"-->
<!--      />-->

      <address-displayer city-only :address="result.type === 'user:patient' ? result.address : result.main_address" />
    </v-list-item-content>
    <v-list-item-action v-if="!$store.state.layout.isMobile">
      <v-chip v-if="result.type === 'user:professional'" class="white--text primary" x-small
              v-text="returnType(result.type)"
      />
      <v-chip v-else-if="result.type === 'user:patient'" class="white--text secondary" x-small
              v-text="returnType(result.type)"
      />
<!--      <v-chip v-else-if="result.type === 'groups'" class="white&#45;&#45;text" x-small-->
<!--              style="background-color: #d66a98 !important;" v-text="returnType(result.type)"-->
<!--      />-->
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  import AddressDisplayer from '../../core/components/AddressDisplayer'
  import {fromNonRetardToRetardFormat} from '@/utils/date'
  import UserAvatarImg from '@/modules/core/layout/UserAvatarImg'

  export default {
    name: 'SearchResultItem',
    components: {UserAvatarImg, AddressDisplayer},
    props: {
      result: {
        type: Object
      }
    },
    methods: {
      returnDistance (distance) {
        if(distance > 0 && distance < 1) {
          return 'à ' + parseInt((distance * 1000).toString()) + ' m'
        }
        else if(distance >= 1) {
          return 'à ' + parseFloat(distance).toFixed(2) + ' km'
        }
        return ''
      },
      formatDate(date) {
        return fromNonRetardToRetardFormat(date, 'date')
      },
      returnType(type) {
        let resType = ''
        switch (type) {
          case 'user:professional':
            resType = 'Professionnel'
            break
          case 'user:patient':
            resType = 'Patient'
            break
          // case 'groups':
          //   resType = 'Groupe'
          //   break
          default:
            resType = 'Utilisateur'
            break
        }
        return resType
      }
    }
  }
</script>
